import React from "react"

const Write: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        d="M2.0013 2.66667C2.0013 2.29848 2.29978 2 2.66797 2H13.3346C13.7028 2 14.0013 2.29848 14.0013 2.66667C14.0013 3.03486 13.7028 3.33333 13.3346 3.33333H2.66797C2.29978 3.33333 2.0013 3.03486 2.0013 2.66667Z"
        fill="currentColor"
      />
      <path
        d="M2.0013 5.33333C2.0013 4.96514 2.29978 4.66667 2.66797 4.66667H7.0013C7.36949 4.66667 7.66797 4.96514 7.66797 5.33333C7.66797 5.70152 7.36949 6 7.0013 6H2.66797C2.29978 6 2.0013 5.70152 2.0013 5.33333Z"
        fill="currentColor"
      />
      <path
        d="M2.66667 7.33333C2.29848 7.33333 2 7.63181 2 8C2 8.36819 2.29848 8.66667 2.66667 8.66667H4.66813C5.03632 8.66667 5.3348 8.36819 5.3348 8C5.3348 7.63181 5.03632 7.33333 4.66813 7.33333H2.66667Z"
        fill="currentColor"
      />
      <path
        d="M12.8389 5.8047C12.0579 5.02365 10.7916 5.02365 10.0105 5.8047L4.86323 10.952C4.73821 11.077 4.66797 11.2466 4.66797 11.4234V14C4.66797 14.3682 4.96645 14.6666 5.33464 14.6666H7.91121C8.08802 14.6666 8.25759 14.5964 8.38262 14.4714L13.5299 9.32409C14.3109 8.54304 14.3109 7.27671 13.5299 6.49566L12.8389 5.8047Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Write
