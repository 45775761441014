import React from "react"

const Envelope: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M1.59 4.656a3 3 0 0 0-.067.448c-.023.28-.023.622-.023 1.017v5.758c0 .395 0 .736.023 1.017.024.297.078.592.222.875.216.424.56.768.984.984.283.144.578.198.875.222.28.023.622.023 1.017.023h8.758c.395 0 .736 0 1.017-.023.297-.024.592-.078.875-.222a2.25 2.25 0 0 0 .984-.984c.144-.283.198-.578.222-.875.023-.28.023-.622.023-1.017V6.12c0-.395 0-.736-.023-1.017q-.016-.226-.067-.448l-5.985 4.897a2.25 2.25 0 0 1-2.85 0z"
      ></path>
      <path
        fill="currentColor"
        d="M15.555 3.417a2 2 0 0 0-.284-.172c-.283-.144-.578-.198-.875-.222C14.116 3 13.774 3 13.38 3H4.62c-.395 0-.736 0-1.017.023-.297.024-.592.078-.875.222q-.15.075-.284.172l6.08 4.975a.75.75 0 0 0 .95 0z"
      ></path>
    </svg>
  )
}

export default Envelope
