import React from "react"
import { solid, outline, other, flag } from "../icons"
import clsx from "clsx"

type WrapperProps = {
  className?: string
  isLoading?: boolean
  children?: React.ReactNode
}
export const Wrapper: React.FC<WrapperProps> = ({
  className,
  isLoading,
  children,
}) => {
  const wrapperClasses = ["flex"]
  if (isLoading) {
    wrapperClasses.push("spin-fast")
  }

  return (
    <div className={clsx(wrapperClasses.join(" "), className)}>{children}</div>
  )
}

export type OtherIcon = keyof typeof other

interface IconProps extends React.HTMLAttributes<HTMLElement> {
  name?: OtherIcon
}

interface OtherIconProps extends Omit<IconProps, "name"> {
  name: OtherIcon
}

function Other(props: OtherIconProps) {
  const { name, ...rest } = props
  const SVG = other[name]
  return <SVG {...rest} />
}

export type OutlineIcon = keyof typeof outline

interface OutlineProps extends Omit<IconProps, "name"> {
  name: OutlineIcon
}

function Outline(props: OutlineProps) {
  const { name, ...rest } = props
  const SVG = outline[name]
  return <SVG {...rest} />
}

export type SolidIcon = keyof typeof solid

interface SolidProps extends Omit<IconProps, "name"> {
  name: SolidIcon
}

function Solid(props: SolidProps) {
  const { name, ...rest } = props
  const SVG = solid[name]
  return <SVG {...rest} />
}

export type FlagIcon = keyof typeof flag

interface FlagProps extends Omit<IconProps, "name"> {
  name: FlagIcon
}

function Flag(props: FlagProps) {
  const { name, ...rest } = props
  const SVG = flag[name]
  return <SVG {...rest} />
}

export const Icon = {
  Other,
  Outline,
  Solid,
  Flag,
}

export type IconType = "other" | "outline" | "solid" | "flag"
