import React from "react"

const Group2: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        d="M2.33325 4.66667C2.33325 3.19391 3.52716 2 4.99992 2C6.47268 2 7.66659 3.19391 7.66659 4.66667C7.66659 6.13943 6.47268 7.33333 4.99992 7.33333C3.52716 7.33333 2.33325 6.13943 2.33325 4.66667Z"
        fill="currentColor"
      />
      <path
        d="M8.33325 4.66667C8.33325 3.19391 9.52716 2 10.9999 2C12.4727 2 13.6666 3.19391 13.6666 4.66667C13.6666 6.13943 12.4727 7.33333 10.9999 7.33333C9.52716 7.33333 8.33325 6.13943 8.33325 4.66667Z"
        fill="currentColor"
      />
      <path
        d="M4.99975 8C6.9136 8 8.729 9.32044 9.40467 11.7424C9.75796 13.0087 8.67789 14 7.56912 14H2.43038C1.3216 14 0.24153 13.0087 0.594821 11.7424C1.27049 9.32043 3.08589 8 4.99975 8Z"
        fill="currentColor"
      />
      <path
        d="M10.6895 11.3841C10.3643 10.2184 9.80518 9.23238 9.08651 8.46807C9.68353 8.15907 10.3359 8 11.0002 8C12.9141 8 14.7295 9.32044 15.4051 11.7424C15.7584 13.0087 14.6784 14 13.5696 14H10.2204C10.7324 13.3033 10.9662 12.3761 10.6895 11.3841Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Group2
