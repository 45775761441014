import React from "react"

const ChevronDownSmall: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.529 9.805c.26.26.682.26.942 0l2.667-2.667a.667.667 0 10-.943-.943L8 8.391 5.805 6.195a.667.667 0 10-.943.943l2.667 2.667z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default ChevronDownSmall
