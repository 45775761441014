import React from "react"

const LayoutLeft: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.968 2.5h8.064c.44 0 .818 0 1.13.025.33.027.658.087.973.247a2.5 2.5 0 0 1 1.092 1.093c.161.315.22.643.247.972.026.313.026.692.026 1.13v8.065c0 .44 0 .818-.026 1.13-.026.33-.086.658-.247.973a2.5 2.5 0 0 1-1.092 1.092c-.315.161-.643.22-.973.247-.312.026-.69.026-1.13.026H5.968c-.44 0-.818 0-1.13-.026-.33-.026-.658-.086-.973-.247a2.5 2.5 0 0 1-1.093-1.092c-.16-.315-.22-.643-.247-.973-.025-.312-.025-.69-.025-1.13V5.968c0-.44 0-.818.025-1.13.027-.33.087-.658.247-.973a2.5 2.5 0 0 1 1.093-1.093c.315-.16.643-.22.972-.247.313-.025.692-.025 1.13-.025m3.199 13.333H14c.48 0 .79 0 1.027-.02.226-.018.31-.05.351-.07a.83.83 0 0 0 .364-.365c.021-.04.053-.125.071-.351.02-.236.02-.546.02-1.027V6c0-.48 0-.79-.02-1.027-.018-.226-.05-.31-.07-.351a.83.83 0 0 0-.365-.364c-.04-.021-.125-.053-.351-.071A14 14 0 0 0 14 4.167H9.167zM7.5 4.167v11.666H6c-.48 0-.79 0-1.027-.02-.226-.018-.31-.05-.351-.07a.83.83 0 0 1-.364-.365c-.021-.04-.053-.125-.071-.351A14 14 0 0 1 4.167 14V6c0-.48 0-.79.02-1.027.018-.226.05-.31.07-.351a.83.83 0 0 1 .365-.364c.04-.021.125-.053.351-.071.236-.02.547-.02 1.027-.02z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default LayoutLeft
