import React from "react"

const Notebook: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        d="M3.99992 1.69428C3.05401 1.85297 2.33325 2.67563 2.33325 3.66663V12.3333C2.33325 13.3243 3.05401 14.1469 3.99992 14.3056V1.69428Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33325 14.3333H11.6666C12.7712 14.3333 13.6666 13.4379 13.6666 12.3333V3.66663C13.6666 2.56206 12.7712 1.66663 11.6666 1.66663H5.33325V14.3333ZM8.66659 4.66663C8.2984 4.66663 7.99992 4.9651 7.99992 5.33329C7.99992 5.70148 8.2984 5.99996 8.66659 5.99996H10.3333C10.7014 5.99996 10.9999 5.70148 10.9999 5.33329C10.9999 4.9651 10.7014 4.66663 10.3333 4.66663H8.66659ZM8.66659 7.33329C8.2984 7.33329 7.99992 7.63177 7.99992 7.99996C7.99992 8.36815 8.2984 8.66663 8.66659 8.66663H10.3333C10.7014 8.66663 10.9999 8.36815 10.9999 7.99996C10.9999 7.63177 10.7014 7.33329 10.3333 7.33329H8.66659Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Notebook
