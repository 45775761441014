import CarouselSlides from "./carousel-slides"
import Darkmoon from "./dark-moon"
import FileText from "./file-text"
import GraduateCap from "./graduate-cap"
import Group2Users from "./group2-users"
import ImacComputer from "./imac-computer"
import LayoutLeft from "./layout-left"
import LuggageBag from "./luggage-bag"
import SunLight from "./sun-light"
import TelevisionOld from "./television-old"
import ThumbsUp from "./thumbs-up"
import Toolbox from "./toolbox"

export const outline = {
  ThumbsUp,
  TelevisionOld,
  SunLight,
  Darkmoon,
  ImacComputer,
  FileText,
  GraduateCap,
  Group2Users,
  LayoutLeft,
  LuggageBag,
  Toolbox,
  CarouselSlides,
}
