import React from "react"
import { Logo, LogoIcon } from "../../svg"
import Section from "./section-component"
import Link from "../link"
import NewsletterSubscription from "./NewsletterSubscription"
import ThemeSelector from "./ThemeSelector"
import cn from "../../utils/classname"
import ButtonLink from "../../ui/button-link"

const socialIcons = {
  facebook: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M18 9.95a8.084 8.084 0 00-1.309-4.313 8.014 8.014 0 00-3.409-2.928A7.956 7.956 0 004.767 3.96 8.055 8.055 0 002.334 7.75a8.097 8.097 0 00-.027 4.51 8.056 8.056 0 002.389 3.816A7.98 7.98 0 008.75 18v-5.705h-2V9.95h2V8.17a2.863 2.863 0 01.766-2.286 2.829 2.829 0 012.234-.874c.6.008 1.198.062 1.79.161v2.013h-1a1.153 1.153 0 00-.953.32 1.167 1.167 0 00-.347.947v1.52h2.22l-.36 2.344h-1.85v5.635a7.988 7.988 0 004.848-2.741A8.08 8.08 0 0018 9.95z"></path>
    </svg>
  ),
  rss: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M4.167 17.5c-.459 0-.851-.163-1.177-.49a1.605 1.605 0 01-.49-1.177c0-.458.163-.85.49-1.177.326-.326.718-.49 1.177-.49.458 0 .85.164 1.177.49.326.327.49.719.49 1.177 0 .459-.164.851-.49 1.177-.327.327-.719.49-1.177.49zm11.25 0c-.361 0-.663-.132-.907-.396a1.625 1.625 0 01-.427-.937 11.43 11.43 0 00-1.093-3.782 11.69 11.69 0 00-2.24-3.135 11.69 11.69 0 00-3.135-2.24 11.43 11.43 0 00-3.782-1.093 1.624 1.624 0 01-.937-.427 1.182 1.182 0 01-.396-.907c0-.36.125-.656.375-.885.25-.23.549-.33.896-.302 1.708.153 3.309.587 4.802 1.302a14.229 14.229 0 013.948 2.781 14.23 14.23 0 012.781 3.948 13.882 13.882 0 011.302 4.802c.028.347-.073.646-.302.896-.23.25-.524.375-.885.375zm-5 0a1.24 1.24 0 01-.896-.365 1.66 1.66 0 01-.48-.885 6.326 6.326 0 00-.656-1.885 6.795 6.795 0 00-1.177-1.573 6.797 6.797 0 00-1.573-1.177 6.325 6.325 0 00-1.885-.657 1.66 1.66 0 01-.885-.479 1.24 1.24 0 01-.365-.896c0-.36.125-.66.375-.895.25-.237.549-.327.896-.271a8.821 8.821 0 012.844.885 9.34 9.34 0 014.083 4.083c.451.882.746 1.83.885 2.844.056.347-.034.646-.27.896a1.18 1.18 0 01-.896.375z"></path>
    </svg>
  ),
  twitter: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.734 5.636H5.381l3.456 4.942-3.277 3.786h1.072l2.682-3.106 2.172 3.106h3.353l-3.673-5.25 3.003-3.478h-1.055l-2.422 2.799-1.958-2.799zm4.482 7.863h-1.301L7.003 6.497h1.3l4.913 7.002z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  instagram: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M13.248 7.543a1.38 1.38 0 00-.791-.791 2.305 2.305 0 00-.774-.144c-.439-.02-.57-.025-1.683-.025s-1.244.005-1.683.025a2.305 2.305 0 00-.774.144 1.38 1.38 0 00-.791.791c-.092.248-.14.51-.144.774-.02.439-.025.57-.025 1.683s.005 1.244.025 1.683c.003.265.052.527.144.774a1.381 1.381 0 00.791.791c.248.092.51.14.774.144.439.02.57.024 1.683.024s1.244-.004 1.683-.025c.265-.003.527-.051.774-.143a1.38 1.38 0 00.791-.791c.092-.248.14-.51.144-.774.02-.439.024-.57.024-1.683s-.004-1.244-.025-1.683a2.302 2.302 0 00-.143-.774zM10 12.14a2.14 2.14 0 110-4.28 2.14 2.14 0 010 4.28zm2.224-3.864a.5.5 0 110-1 .5.5 0 010 1zM11.39 10a1.39 1.39 0 11-2.78 0 1.39 1.39 0 012.78 0zM10 2a8 8 0 100 16 8 8 0 000-16zm4.142 9.717a3.059 3.059 0 01-.194 1.012 2.13 2.13 0 01-1.219 1.219 3.06 3.06 0 01-1.01.194c-.446.02-.587.025-1.719.025-1.132 0-1.273-.005-1.718-.025a3.059 3.059 0 01-1.011-.194 2.13 2.13 0 01-1.218-1.219 3.06 3.06 0 01-.195-1.01c-.02-.446-.025-.587-.025-1.719 0-1.132.005-1.273.025-1.718.007-.346.073-.688.194-1.011A2.13 2.13 0 017.27 6.05a3.058 3.058 0 011.01-.193c.446-.02.587-.025 1.719-.025 1.132 0 1.273.005 1.718.025.346.007.688.073 1.011.194a2.13 2.13 0 011.219 1.219c.12.323.186.665.194 1.01.02.446.025.587.025 1.719 0 1.132-.005 1.273-.025 1.717z"></path>
    </svg>
  ),
}

const Footer = () => {
  return (
    <div className="border-t border-neutral-alpha-4">
      <div className="container-2xl">
        <div
          className={cn(
            "border-b border-neutral-alpha-4",
            "py-16 justify-between gap-x-5 gap-y-14",
            "grid grid-cols-[auto,auto] md:grid-cols-[auto,auto,auto] lg:grid-cols-[auto,auto,auto,auto,auto,auto]"
          )}
        >
          <Link to="/" className="hidden lg:block">
            <LogoIcon className="fill-current text-neutral-12 size-8" />
          </Link>
          <Section
            className={"order-2 lg:order-1"}
            title="Stuff we do"
            items={[
              { href: "/premium/library/", label: "Premium", external: true },
              { href: "/newsletters/", label: "Newsletters" },
              {
                href: "/premium/library/paths/",
                label: "Learning paths",
                external: true,
              },
              { href: "/premium/library/", label: "Library", external: true },
              { href: "/community/", label: "Forums", external: true },
            ]}
          />
          <Section
            className={"order-3 lg:order-2"}
            title="Contact"
            items={[
              { href: "/contact-us/", label: "Contact us" },
              {
                href: "https://sitepointhq.notion.site/Sitepoint-FAQs-619b2b88af4f4a5db27beade7ca2cce6",
                label: "FAQ",
              },
              {
                href: "https://sitepoint.typeform.com/to/HtAXVN",
                label: "Publish your book with us",
              },
              {
                href: "/write-for-us/",
                label: "Write an article with us",
              },
              { href: "/partnerships/", label: "Advertise" },
            ]}
          />
          <Section
            className={"order-4 lg:order-3"}
            title="About"
            items={[
              { href: "/about-us/", label: "Our story" },
              {
                href: "/premium-for-teams/",
                label: "Corporate memberships",
              },
              { href: "/legals/", label: "Terms of use" },
              { href: "/privacy-policy/", label: "Privacy policy" },
            ]}
          />
          <Section
            className={"order-5 lg:order-4"}
            title="Connect"
            items={[
              {
                icon: socialIcons.rss,
                href: "/sitepoint.rss",
                label: "RSS",
                external: true,
                target: "_blank",
                rel: "noopener noreferrer",
              },
              {
                icon: socialIcons.facebook,
                href: "https://www.facebook.com/sitepoint",
                label: "Facebook",
                target: "_blank",
                rel: "noopener noreferrer",
              },
              {
                icon: socialIcons.instagram,
                href: "https://www.instagram.com/sitepointdotcom/?hl=en",
                label: "Instagram",
                target: "_blank",
                rel: "noopener noreferrer",
              },
              {
                icon: socialIcons.twitter,
                href: "https://twitter.com/sitepointdotcom",
                label: "Twitter (X)",
                target: "_blank",
                rel: "noopener noreferrer",
              },
            ]}
          />
          <div className=" order-1 col-span-2 md:col-span-3 lg:col-span-1 lg:order-6 flex flex-col gap-14">
            <div className="flex lg:hidden justify-between">
              <Link to="/" className="block">
                <Logo className="fill-current text-neutral-12 w-[147px]" />
              </Link>
              <ThemeSelector />
            </div>
            <div className="w-full md:w-[317px]">
              <NewsletterSubscription />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center gap-4 items-center md:justify-between py-14 lg:py-16 relative">
          <div className="text-jar-3 font-normal text-neutral-alpha-11 text-center md:text-left">
            &copy; 2000 &ndash; {new Date().getFullYear()} SitePoint Pty. Ltd.
          </div>

          <div className="lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:top-1/2 lg:-translate-y-1/2 ">
            <ButtonLink
              size={2}
              weight={"regular"}
              contrast={"high"}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // For smooth scrolling
                })
              }}
            >
              Back to top
            </ButtonLink>
          </div>
          <div className="hidden lg:block">
            <ThemeSelector />
          </div>
        </div>
        <div className="pb-8 justify-between flex gap-5 flex-col md:flex-row md:items-start lg:items-center">
          <div className="text-jar-3 text-neutral-11 text-center md:text-left">
            This site is protected by reCAPTCHA and the Google Privacy Policy
            and Terms of Service apply.
          </div>
          <div className="flex gap-5 shrink-0 justify-center items-center md:justify-start flex-col md:flex-row">
            <ButtonLink
              size={2}
              weight={"regular"}
              underline={false}
              href="https://policies.google.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
              contrast={"default"}
              theme={"neutral"}
            >
              Privacy Policy
            </ButtonLink>

            <ButtonLink
              size={2}
              weight={"regular"}
              underline={false}
              href="https://policies.google.com/terms"
              rel="noopener noreferrer"
              target="_blank"
              theme={"neutral"}
            >
              Terms of Service
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
