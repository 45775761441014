import AppleLogo from "./apple-logo"
import ChevronDownSmall from "./chevron-down-small"
import ChevronTopSmall from "./chevron-top-small"
import IconPlaceholder from "./icon-placeholder"
import ChevronRightSmall from "./chevron-right-small"
import ChevronLeftSmall from "./chevron-left-small"
import HTMLLogo from "./html-logo"
import CrossSmall from "./cross-small"
import Twitter from "./twitter"
import Facebook from "./facebook"
import Linkedin from "./linkedin"
import Reddit from "./reddit"
import DiamondPremium from "./diamond-premium"

export const other = {
  AppleLogo,
  ChevronDownSmall,
  ChevronTopSmall,
  IconPlaceholder,
  ChevronRightSmall,
  ChevronLeftSmall,
  HTMLLogo,
  CrossSmall,
  Twitter,
  Facebook,
  Linkedin,
  Reddit,
  DiamondPremium,
}
