import React from "react"

const BubbleMessage: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        d="M10 1.66669C5.39765 1.66669 1.66669 5.39765 1.66669 10C1.66669 11.2526 1.94359 12.4428 2.44031 13.5107C2.45021 13.532 2.44969 13.5441 2.44955 13.546L1.80094 16.2041C1.50763 17.4062 2.58026 18.4975 3.78722 18.225L6.56016 17.5988C6.56194 17.5987 6.57338 17.5983 6.59364 17.6074C7.63463 18.0742 8.78818 18.3334 10 18.3334C14.6024 18.3334 18.3334 14.6024 18.3334 10C18.3334 5.39765 14.6024 1.66669 10 1.66669Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default BubbleMessage
