import React from "react"

const FileText: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.333 4.167a2.5 2.5 0 0 1 2.5-2.5h4.31a2.5 2.5 0 0 1 1.768.732l-.28.279.28-.28 4.023 4.025a2.5 2.5 0 0 1 .733 1.767v7.643a2.5 2.5 0 0 1-2.5 2.5H5.833a2.5 2.5 0 0 1-2.5-2.5zM10 3.333H5.833A.833.833 0 0 0 5 4.167v11.666c0 .46.373.834.833.834h8.334c.46 0 .833-.373.833-.834v-7.5h-2.5a2.5 2.5 0 0 1-2.5-2.5zm3.822 3.334H12.5a.833.833 0 0 1-.833-.834V4.512zm-7.155 4.166c0-.46.373-.833.833-.833H10a.833.833 0 0 1 0 1.667H7.5a.833.833 0 0 1-.833-.834m0 3.334c0-.46.373-.834.833-.834h5.417a.833.833 0 0 1 0 1.667H7.5a.833.833 0 0 1-.833-.833"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default FileText
