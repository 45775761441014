import { getCookie } from "./cookie"

export const isLoggedIn = () => {
  const cookie = getCookie("sp_member")
  return !!cookie
}

export const hasMembership = () => {
  const cookie = getCookie("sp_member")
  try {
    const value = JSON.parse(cookie)
    return !!value.type
  } catch (error) {
    return false
  }
}
