import tokenManager from "./token-manager"

async function handleResponse(response) {
  const { status, headers } = response
  // No Content
  if (status === 204) {
    return { success: true }
  }

  const contentType = headers.get("Content-Type")

  let data

  // Handle more content types if needed
  if (contentType.startsWith("application/json")) {
    data = await response.json()
  } else {
    data = await response.text()
  }

  if (response.ok) {
    return data
  } else {
    if (data && typeof data === "object") {
      return Promise.reject({
        statusCode: status,
        ...data,
      })
    } else {
      return Promise.reject({
        statusCode: status,
        error:
          response.statusText ||
          "Something went wrong. Please try again later.",
      })
    }
  }
}

export function getAuthorizationHeader(token) {
  return token ? { Authorization: `Bearer ${token}` } : null
}

export async function getAccessToken() {
  await tokenManager.waitForTokenRefresh()
  if (tokenManager.isTokenValid()) {
    const token = tokenManager.getToken()
    return token
  } else {
    const newToken = await tokenManager.getRefreshedToken()
    return newToken
  }
}

async function request(url, options = {}) {
  const { body, headers = {}, autoAttachToken = true, ...restOptions } = options

  let authorization = null

  if (autoAttachToken && !headers["Authorization"]) {
    authorization = await getAccessToken()
  }

  const newHeaders = new Headers({
    "Content-Type": "application/json",
    ...headers,
    ...getAuthorizationHeader(authorization),
  })

  if (typeof window === "undefined") {
    newHeaders.set("User-Agent", "premiumnext")
  }

  const requestOptions = {
    ...restOptions,
    headers: newHeaders,
  }

  if (body) {
    requestOptions.body = JSON.stringify(body)
  }

  return fetch(url, requestOptions).then((response) => handleResponse(response))
}

async function GET(url, options = {}) {
  return request(url, { ...options, method: "GET" })
}

async function POST(url, data, options = {}) {
  return request(url, { ...options, body: data, method: "POST" })
}

async function PUT(url, data, options = {}) {
  return request(url, { ...options, body: data, method: "PUT" })
}

async function DELETE(url, data, options = {}) {
  return request(url, { ...options, body: data, method: "DELETE" })
}

export { GET, POST, PUT, DELETE }
