import { useEffect, useState } from "react"

import { isLoggedIn as checkIsLoggedIn } from "../utils/user"

/**
 * Hook to check the value if a user is logged in (based from cookies)
 *
 * The value is `undefined` if it's not executed on the client side.
 */
export default function useIsLoggedIn() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>()

  useEffect(() => {
    return setIsLoggedIn(checkIsLoggedIn())
  }, [])

  return isLoggedIn
}
