import React, { createContext, useContext, useEffect, useMemo } from "react"

import { mixpanel } from "../../services/mixpanel"
import tokenManager from "../../services/token-manager"
import { useCurrentUser } from "./user"
import { AuthContextInterface } from "./types"

export const AuthUserContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
)

export default function AuthUserProvider({ children }) {
  const { data, loading, isLoggedIn } = useCurrentUser()

  const value = useMemo(
    () => ({
      logout: () => {
        tokenManager.eraseToken()
        window.localStorage.setItem(
          tokenManager.getLogoutkey(),
          Date.now().toString()
        )

        // Reset analytics
        mixpanel.reset()
      },
    }),
    []
  )

  useEffect(() => {
    const handleStorageChange = (event) => {
      tokenManager.syncLogout(event)
    }
    window.addEventListener("storage", handleStorageChange)
    return () => {
      window.removeEventListener("storage", handleStorageChange)
      window.localStorage.removeItem(tokenManager.getLogoutkey())
    }
  }, [])

  useEffect(() => {
    if (data?.id) {
      mixpanel?.identify(data?.id)
    }
  }, [data?.id])

  return (
    <AuthUserContext.Provider
      value={{
        loaded: !loading,
        isLogged: isLoggedIn,
        user: data,
        hasMembership: data?.is_member && data.membership_type !== "Free",
        logout: value.logout,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  )
}

export function useAuthUser() {
  const context = useContext(AuthUserContext)

  if (typeof context === "undefined") {
    throw new Error("useAuthUser must be used within a AuthUserProvider")
  }

  return context
}
