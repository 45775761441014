import React from "react"

const ChevronRightSmall: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.74408 6.07745C8.06951 5.75201 8.59715 5.75201 8.92259 6.07745L12.2559 9.41079C12.5814 9.73622 12.5814 10.2639 12.2559 10.5893L8.92259 13.9226C8.59715 14.2481 8.06951 14.2481 7.74408 13.9226C7.41864 13.5972 7.41864 13.0696 7.74408 12.7441L10.4882 10L7.74408 7.25596C7.41864 6.93053 7.41864 6.40289 7.74408 6.07745Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChevronRightSmall
