import React from "react"

const TelevisionOld: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.592 2.405a.833.833 0 0 1 1.17-.146L10 4.778l3.238-2.52a.833.833 0 0 1 1.024 1.316L12.429 5h3.404a2.5 2.5 0 0 1 2.5 2.5V15a2.5 2.5 0 0 1-2.5 2.5H4.167a2.5 2.5 0 0 1-2.5-2.5V7.5a2.5 2.5 0 0 1 2.5-2.5H7.57L5.738 3.574a.833.833 0 0 1-.146-1.169M4.167 6.667a.833.833 0 0 0-.834.833V15c0 .46.373.833.834.833h11.666c.46 0 .834-.373.834-.833V7.5a.833.833 0 0 0-.834-.833z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default TelevisionOld
