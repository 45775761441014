import React from "react"

const Book: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66675 1.33337C3.56218 1.33337 2.66675 2.2288 2.66675 3.33337V12.6667C2.66675 13.7713 3.56218 14.6667 4.66675 14.6667H11.3334C12.438 14.6667 13.3334 13.7713 13.3334 12.6667V3.33337C13.3334 2.2288 12.438 1.33337 11.3334 1.33337H4.66675ZM4.00008 12.6667C4.00008 13.0349 4.29856 13.3334 4.66675 13.3334H11.3334C11.7016 13.3334 12.0001 13.0349 12.0001 12.6667V11.8862C11.7916 11.9599 11.5672 12 11.3334 12H4.66675C4.29856 12 4.00008 12.2985 4.00008 12.6667ZM6.00008 4.00004C5.63189 4.00004 5.33341 4.29852 5.33341 4.66671C5.33341 5.0349 5.63189 5.33337 6.00008 5.33337H10.0001C10.3683 5.33337 10.6667 5.0349 10.6667 4.66671C10.6667 4.29852 10.3683 4.00004 10.0001 4.00004H6.00008ZM5.33341 7.33337C5.33341 6.96518 5.63189 6.66671 6.00008 6.66671H8.00008C8.36827 6.66671 8.66675 6.96518 8.66675 7.33337C8.66675 7.70156 8.36827 8.00004 8.00008 8.00004H6.00008C5.63189 8.00004 5.33341 7.70156 5.33341 7.33337Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Book
