import React from "react"

const CircleExclamation: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm11-2.98a1 1 0 10-2 0v2.993a1 1 0 102 0V9.02zm-1 4.73a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default CircleExclamation
