import React from "react"

const AlignJustify: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 4.16659C2.5 3.70635 2.8731 3.33325 3.33333 3.33325H16.6667C17.1269 3.33325 17.5 3.70635 17.5 4.16659C17.5 4.62682 17.1269 4.99992 16.6667 4.99992H3.33333C2.8731 4.99992 2.5 4.62682 2.5 4.16659ZM2.5 9.99992C2.5 9.53968 2.8731 9.16658 3.33333 9.16658H16.6667C17.1269 9.16658 17.5 9.53968 17.5 9.99992C17.5 10.4602 17.1269 10.8333 16.6667 10.8333H3.33333C2.8731 10.8333 2.5 10.4602 2.5 9.99992ZM2.5 15.8333C2.5 15.373 2.8731 14.9999 3.33333 14.9999H16.6667C17.1269 14.9999 17.5 15.373 17.5 15.8333C17.5 16.2935 17.1269 16.6666 16.6667 16.6666H3.33333C2.8731 16.6666 2.5 16.2935 2.5 15.8333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default AlignJustify
