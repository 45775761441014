import React from "react"

const DotGrid: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.25 3.75a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m5.25 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m5.25 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0M2.25 9a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0M7.5 9a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m5.25 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m-10.5 5.25a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m5.25 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0m5.25 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default DotGrid
