import React from "react"

const LogoIcon = (props) => (
  <svg viewBox="0 0 65 80" {...props}>
    <title>SitePoint</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1223.00, -923.00)" fill="#262626">
        <g transform="translate(1223.00, 923.00)">
          <path d="M1.65,35.63 L7.54,41.35 L26.17,58.74 L34.19,50.99 C34.93,49.98 34.88,48.61 34.04,47.65 L26.71,41.22 L26.73,41.21 L18.83,33.55 C17.91,32.50 17.93,30.93 18.91,29.92 L40.17,9.37 L30.51,0 L1.66,27.86 C-0.55,30.01 -0.55,33.48 1.65,35.63"></path>
          <path d="M62.76,44.32 L56.87,38.60 L38.24,21.21 L30.22,28.96 C29.48,29.97 29.53,31.34 30.37,32.30 L37.70,38.73 L37.67,38.73 L45.58,46.40 C46.50,47.45 46.48,49.01 45.50,50.03 L24.24,70.57 L33.90,79.95 L56.84,57.78 L62.75,52.08 C64.96,49.94 64.97,46.46 62.76,44.32"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default LogoIcon
