import React from "react"

const BarsThree: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.5 4.5a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1-.75-.75m0 4.5a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H2.25A.75.75 0 0 1 1.5 9m0 4.5a.75.75 0 0 1 .75-.75h13.5a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1-.75-.75"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default BarsThree
