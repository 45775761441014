import React from "react"

const Darkmoon: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.08 2.109a.83.83 0 0 1-.034.89A5 5 0 0 0 17 9.954a.833.833 0 0 1 1.303.757A8.333 8.333 0 1 1 9.29 1.697a.83.83 0 0 1 .79.411M7.854 3.686a6.665 6.665 0 1 0 8.46 8.46 6.667 6.667 0 0 1-8.46-8.46"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Darkmoon
