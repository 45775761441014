import React from "react"

const ThumbsUp: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11 2a1 1 0 00-.894.553L6.382 10H4a2 2 0 00-2 2v7a2 2 0 002 2h13.381a4 4 0 003.964-3.46l.681-5A4 4 0 0018.063 8h-3.879l.396-2.538A3 3 0 0011.616 2H11zM6 19v-7H4v7h2z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default ThumbsUp
