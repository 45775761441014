import React from "react"

const Toolbox: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.253 3.333q-.016 0-.026.013L5.186 4.647a.83.83 0 0 0-.183.521v3.165h2.5V5.168a.83.83 0 0 0-.182-.52L6.279 3.345a.03.03 0 0 0-.026-.013m2.917 5V5.168a2.5 2.5 0 0 0-.548-1.562L7.581 2.305a1.7 1.7 0 0 0-2.655 0L3.884 3.606a2.5 2.5 0 0 0-.547 1.562v3.165H2.5a.833.833 0 0 0-.833.834V15a2.5 2.5 0 0 0 2.5 2.5h11.666a2.5 2.5 0 0 0 2.5-2.5V9.167a.833.833 0 0 0-.833-.834h-.833v-5c0-.92-.747-1.666-1.667-1.666h-3.333c-.92 0-1.667.746-1.667 1.666v5zM3.333 10v5c0 .46.373.833.834.833h11.666c.46 0 .834-.373.834-.833v-5zm8.334-1.667H15v-5h-3.333V5h.836a.833.833 0 0 1 0 1.667h-.836z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Toolbox
