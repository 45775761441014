import React from "react"

const Group2Users: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.25 4.167a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333M2.917 5.833a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0M13.75 4.167a1.667 1.667 0 1 0 0 3.333 1.667 1.667 0 0 0 0-3.333m-3.333 1.666a3.333 3.333 0 1 1 6.666 0 3.333 3.333 0 0 1-6.666 0m7.234 9.295c-.781-2.806-2.941-3.796-4.726-3.362a.833.833 0 0 1-.393-1.62c2.762-.671 5.732.97 6.725 4.535.441 1.585-.912 2.819-2.295 2.819h-2.795a.833.833 0 0 1 0-1.667h2.795a.77.77 0 0 0 .58-.248.47.47 0 0 0 .11-.457M6.25 11.667c-1.554 0-3.232 1.062-3.9 3.459a.47.47 0 0 0 .11.458.77.77 0 0 0 .578.25h6.424a.77.77 0 0 0 .579-.25.47.47 0 0 0 .11-.458c-.669-2.397-2.347-3.46-3.901-3.46m0-1.667c2.392 0 4.662 1.65 5.506 4.678.442 1.583-.908 2.822-2.294 2.822H3.038c-1.386 0-2.736-1.24-2.294-2.822C1.588 11.65 3.858 10 6.25 10"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Group2Users
