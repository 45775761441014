import React, { useId } from "react"

const DiamondPremium: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  const maskId = useId()
  const filterId = useId()
  const paintId = useId()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <mask
        id={maskId}
        width="24"
        height="18"
        x="0"
        y="4"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
      >
        <path
          fill="#fff"
          d="M1.433 11.247a2.4 2.4 0 0 1-.16-3.134L3.75 4.928a2.4 2.4 0 0 1 1.894-.926h12.759a2.4 2.4 0 0 1 1.904.94l2.439 3.178a2.4 2.4 0 0 1-.166 3.116l-8.818 9.26a2.4 2.4 0 0 1-3.472.005z"
        ></path>
      </mask>
      <g mask={`url(#${maskId})`}>
        <path
          fill={`url(#${paintId})`}
          d="m-.001 9.75 4.472-5.748h15.116l4.41 5.747-11.968 12.567z"
        ></path>
        <path
          fill="#000"
          fillOpacity="0.1"
          d="m-.001 9.75 5.538 1.828 6.492 10.738zM23.966 9.75l-5.63 1.828-6.307 10.738z"
        ></path>
        <path
          fill="#fff"
          d="M11.938 5.26 4.423 4l1.115 7.578h12.798L19.452 4z"
          style={{ mixBlendMode: "overlay" }}
        ></path>
        <path
          fill="#fff"
          d="m4.423 4 7.607 2.526 7.558-2.523z"
          style={{ mixBlendMode: "soft-light" }}
        ></path>
        <path
          fill="#fff"
          d="m11.937 5.259-6.4 6.319h12.798z"
          style={{ mixBlendMode: "overlay" }}
        ></path>
        <path
          fill="#fff"
          d="m12.03 6.526-6.493 5.052h12.798z"
          style={{ mixBlendMode: "soft-light" }}
        ></path>
        <path
          fill="#fff"
          d="M5.537 11.578 0 9.75 4.422 4h1.115zM18.336 11.578l5.663-1.829L19.576 4h-1.115z"
          style={{ mixBlendMode: "soft-light" }}
        ></path>
        <path
          fill="#fff"
          d="m5.537 11.578 6.46 10.722 6.338-10.722z"
          opacity="0.5"
          style={{ mixBlendMode: "soft-light" }}
        ></path>
        <g
          filter={`url(#${filterId})`}
          opacity="0.3"
          style={{ mixBlendMode: "soft-light" }}
        >
          <path fill="#000" d="m5.537 11.578 6.46 10.722 6.338-10.722z"></path>
        </g>
      </g>
      <defs>
        <linearGradient
          id={paintId}
          x1="11.998"
          x2="11.998"
          y1="4.002"
          y2="22.316"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AA41FB"></stop>
          <stop offset="0.432" stopColor="#413FCB"></stop>
          <stop offset="1" stopColor="#BB29FF"></stop>
        </linearGradient>
        <filter
          id={filterId}
          width="12.798"
          height="11.922"
          x="5.537"
          y="10.378"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-1.2"></feOffset>
          <feGaussianBlur stdDeviation="4.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="shape"
            result="effect1_innerShadow_2747_33948"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  )
}

export default DiamondPremium
