import React from "react"

const CarouselSlides: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.142 4.167A2.5 2.5 0 0 1 7.5 2.5h5a2.5 2.5 0 0 1 2.358 1.667h.975a2.5 2.5 0 0 1 2.5 2.5v6.666a2.5 2.5 0 0 1-2.5 2.5h-.975A2.5 2.5 0 0 1 12.5 17.5h-5a2.5 2.5 0 0 1-2.358-1.667h-.975a2.5 2.5 0 0 1-2.5-2.5V6.667a2.5 2.5 0 0 1 2.5-2.5zM5 5.833h-.833a.833.833 0 0 0-.834.834v6.666c0 .46.373.834.834.834H5zm10 8.334h.833c.46 0 .834-.373.834-.834V6.667a.833.833 0 0 0-.834-.834H15zm-7.5-10A.833.833 0 0 0 6.667 5v10c0 .46.373.833.833.833h5c.46 0 .833-.373.833-.833V5a.833.833 0 0 0-.833-.833z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default CarouselSlides
