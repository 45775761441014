import React from "react"

const IconPlaceholder: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M6.675 18.6h10.65l-2.2-2.2a5.145 5.145 0 01-1.45.739 5.34 5.34 0 01-1.674.261 5.254 5.254 0 01-1.675-.267 5.436 5.436 0 01-1.45-.733l-2.2 2.2zM5.4 17.35l2.2-2.2a5.207 5.207 0 01-.739-1.462A5.43 5.43 0 016.601 12c0-.585.088-1.143.266-1.675a5.43 5.43 0 01.733-1.45l-2.2-2.2V17.35zm3.5-3.5L10.725 12 8.9 10.175a3.38 3.38 0 00-.375.868A3.61 3.61 0 008.4 12c0 .333.042.655.125.966.083.31.208.605.375.884zM12 15.6c.333 0 .652-.042.957-.125.305-.083.594-.208.868-.375L12 13.275 10.175 15.1c.267.167.553.292.857.375.304.083.627.125.968.125zm0-4.875L13.825 8.9a3.38 3.38 0 00-.868-.375A3.61 3.61 0 0012 8.4a3.61 3.61 0 00-.957.125 3.38 3.38 0 00-.868.375L12 10.725zm3.1 3.1c.167-.274.292-.563.375-.868.083-.305.125-.624.125-.957a3.65 3.65 0 00-.125-.96 3.256 3.256 0 00-.375-.865L13.275 12l1.825 1.825zm3.5 3.5V6.675l-2.2 2.2c.311.434.556.918.733 1.45.178.531.267 1.09.267 1.674a5.35 5.35 0 01-.261 1.675 5.154 5.154 0 01-.739 1.451l2.2 2.2zM15.125 7.6l2.2-2.2H6.675l2.2 2.2a5.147 5.147 0 011.45-.739 5.343 5.343 0 011.674-.261c.584 0 1.142.089 1.675.267a5.437 5.437 0 011.451.733zM5.4 20.4c-.495 0-.919-.176-1.271-.529A1.733 1.733 0 013.6 18.6V5.4c0-.495.176-.919.529-1.271A1.733 1.733 0 015.4 3.6h13.2c.495 0 .919.176 1.271.529.353.352.53.776.53 1.271v13.2c0 .495-.177.919-.53 1.271a1.733 1.733 0 01-1.27.529H5.4z"
      ></path>
    </svg>
  )
}

export default IconPlaceholder
