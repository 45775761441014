import React from "react"

const LuggageBag: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.919 4.167a.417.417 0 0 0-.417.416v1.25h5v-1.25a.417.417 0 0 0-.417-.416zm6.25 1.666v-1.25c0-1.15-.933-2.083-2.084-2.083H7.918c-1.15 0-2.083.933-2.083 2.083v1.25H4.167a2.5 2.5 0 0 0-2.5 2.5V10q0 .028.002.056v.722a1 1 0 0 0 0 .111V15a2.5 2.5 0 0 0 2.5 2.5h11.666a2.5 2.5 0 0 0 2.5-2.5v-5q0-.028-.002-.056v-1.61a2.5 2.5 0 0 0-2.5-2.5zM4.166 7.5a.833.833 0 0 0-.834.833v1.611l.002.056h13.332V8.333a.833.833 0 0 0-.834-.833zm12.502 4.167h-5.835l.001 1.666a.833.833 0 0 1-1.667 0v-1.666H3.334V15c0 .46.373.833.833.833h11.667c.46 0 .834-.373.834-.833z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default LuggageBag
