import React from "react"
import ButtonLink from "../../ui/button-link"
import cn from "../../utils/classname"

const Section = ({ title, items, className }) => (
  <div className={cn("flex flex-col gap-5", className)}>
    <div className="text-jar-3 font-medium text-neutral-12">{title}</div>
    <div className="flex flex-col gap-4">
      {items.map(({ label, icon, ...rest }, index) => (
        <ButtonLink
          {...rest}
          key={index}
          size={2}
          theme={"neutral"}
          weight={"regular"}
          underline={false}
          className="font-medium inline-flex items-center space-x-1"
          color="neutral"
        >
          {label}
        </ButtonLink>
      ))}
    </div>
  </div>
)

export default Section
