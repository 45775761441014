import React from "react"

const ImacComputer: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 6a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-4v3a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-3H5a3 3 0 0 1-3-3zm2 8v1a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-1zm16-2H4V6a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1zm-9 6v2h2v-2z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default ImacComputer
