/**
 * This is used to manage the getting and refreshing the access tokens.
 * Based from:
 * https://marmelab.com/blog/2020/07/02/manage-your-jwt-react-admin-authentication-in-memory.html
 * https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/
 */
import { differenceInMilliseconds, isValid, isBefore } from "date-fns"

const API_URL = process.env.GATSBY_BASE_URL + "/premium/api/v1"

const tokenManager = () => {
  const logoutKey = "logout"
  const bufferMs = 5000

  let accessToken = null
  let expiryDate = null
  let refreshPromise = null
  let refreshTimeOutId

  const getLogoutkey = () => logoutKey

  const getToken = () => accessToken

  const setToken = (token, expiry) => {
    accessToken = token
    const convertedDate = new Date(expiry)
    if (isValid(convertedDate)) {
      expiryDate = convertedDate
      refreshToken(convertedDate)
    }
  }

  const isTokenValid = () => {
    const now = new Date()
    if (accessToken && isValid(expiryDate)) {
      return isBefore(now, expiryDate)
    }

    return false
  }

  const refreshToken = (expiry) => {
    // Validity period of the token, minus 5 seconds
    const ms = differenceInMilliseconds(expiry, new Date())
    const delay = Math.max(ms - bufferMs, 1)
    window?.clearTimeout(refreshTimeOutId)
    refreshTimeOutId = window?.setTimeout(getRefreshedToken, delay)
  }

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window?.clearTimeout(refreshTimeOutId)
    }
  }

  const eraseToken = () => {
    accessToken = null
    expiryDate = null
    abortRefreshToken()
  }

  const syncLogout = (event) => {
    if (event.key === "logout") {
      eraseToken()
    }
  }

  const waitForTokenRefresh = () => {
    if (!refreshPromise) {
      return Promise.resolve()
    }
    return refreshPromise.then(() => {
      refreshPromise = null
      return true
    })
  }

  const getRefreshedToken = (options = {}) => {
    const { headers = {} } = options

    const newHeaders = new Headers({
      "Content-Type": "application/json",
      ...headers,
    })

    if (typeof window === "undefined") {
      newHeaders.set("User-Agent", "premiumnext")
    }

    const requestOptions = {
      ...options,
      headers: newHeaders,
      method: "GET",
    }

    // Use plain fetch for requesting the refresh token
    refreshPromise = fetch(`${API_URL}/refresh_token/`, requestOptions)
      .then((response) => {
        if (response.status !== 200) {
          eraseToken()
          return { access_token: null }
        }
        return response.json()
      })
      .then(({ access_token, expiry }) => {
        if (access_token) {
          setToken(access_token, expiry)
          return access_token
        }

        return null
      })
      .catch(() => {
        return null
      })

    return refreshPromise
  }

  /* Expose the necessary functions only */
  return {
    getLogoutkey,
    getToken,
    setToken,
    eraseToken,
    getRefreshedToken,
    waitForTokenRefresh,
    syncLogout,
    isTokenValid,
  }
}

export default tokenManager()
