import React from "react"

const MagnifyingGlass: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.25 3.75a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9m-6 4.5a6 6 0 1 1 10.74 3.68l2.54 2.54a.75.75 0 1 1-1.06 1.06l-2.54-2.54a6 6 0 0 1-9.68-4.74"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default MagnifyingGlass
