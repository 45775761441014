import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { IconType, OtherIcon, OutlineIcon, SolidIcon } from "../icon"
import IconRenderer from "../icon/icon-renderer"
import { cva, type VariantProps } from "class-variance-authority"
import cn from "../../utils/classname"

interface IconProps {
  type: IconType
  startIconName?: OtherIcon | OutlineIcon | SolidIcon
  endIconName?: OtherIcon | OutlineIcon | SolidIcon
}

export interface ButtonLinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "type">,
    VariantProps<typeof buttonLinkVariants> {
  href?: string
  activeClassName?: string
  partiallyActive?: boolean
  external?: boolean
  icon?: IconProps
}

export type ButtonLinkElement = HTMLAnchorElement & GatsbyLink<{}>

const buttonLinkVariants = cva(
  [
    "items-center",
    "justify-center",
    "transition-all",
    "duration-300",
    "ease-out",
    "box-border",
    "w-fit",
  ],
  {
    variants: {
      contrast: {
        high: [],
        default: [],
      },
      weight: {
        regular: ["font-normal"],
        medium: ["font-medium"],
        semibold: ["font-semibold"],
      },
      theme: {
        primary: [],
        neutral: [],
      },
      size: {
        1: ["pb-px", "text-jar-2", "gap-1"],
        2: ["pb-0", "text-jar-3", "gap-1.5"],
        3: ["pb-0", "text-jar-4", "gap-2"],
        4: ["pb-0", "text-jar-5", "gap-2"],
      },
      type: {
        icon: ["p-0", "gap-0"],
        text: [],
      },
      underline: {
        true: ["border-b"],
        false: ["border-b-transparent"],
      },
      inline: { true: ["inline-flex"], false: ["flex"] },
    },
    compoundVariants: [
      {
        contrast: "default",
        theme: "primary",
        className:
          "text-primary-alpha-11 border-primary-alpha-7 hover:text-primary-alpha-12 hover:border-primary-alpha-12",
      },
      {
        contrast: "high",
        theme: "primary",
        className:
          "text-primary-alpha-12 border-primary-alpha-12 hover:text-primary-alpha-11 hover:border-primary-alpha-11",
      },
      {
        contrast: "default",
        theme: "neutral",
        className:
          "text-neutral-alpha-11 border-neutral-alpha-7 hover:text-neutral-alpha-12 hover:border-neutral-alpha-12",
      },
      {
        contrast: "high",
        theme: "neutral",
        className:
          "text-neutral-alpha-12 border-neutral-alpha-12 hover:text-neutral-alpha-11 hover:border-neutral-alpha-11",
      },
      { type: "icon", size: 1, className: "size-5" },
      { type: "icon", size: 2, className: "size-[22px]" },
      { type: "icon", size: 3, className: "size-6" },
      { type: "icon", size: 4, className: "size-7" },
    ],
    defaultVariants: {
      contrast: "default",
      weight: "regular",
      theme: "primary",
      size: 2,
      type: "text",
      underline: true,
      inline: false,
    },
  }
)

const iconVariants = cva([], {
  variants: {
    size: {
      1: "size-4",
      2: "size-5",
      3: "size-6",
      4: "size-6",
    },
  },
  defaultVariants: {
    size: 2,
  },
})

const ButtonLink = React.forwardRef<
  ButtonLinkElement | HTMLButtonElement,
  ButtonLinkProps
>(
  (
    {
      href,
      children,
      activeClassName,
      partiallyActive,
      external,
      className,
      size,
      theme,
      weight,
      icon,
      contrast,
      underline,
      type,
      inline,
      ...props
    },
    ref
  ) => {
    const baseClassName = cn(
      buttonLinkVariants({
        size,
        theme,
        weight,
        contrast,
        underline,
        type,
        inline,
      }),
      className
    )
    const {
      startIconName,
      endIconName,
      type: iconType,
    } = icon || {
      startIcon: undefined,
      endIcon: undefined,
      type: undefined,
    }

    function renderChildren() {
      return (
        <>
          <IconRenderer
            className={iconVariants({ size })}
            iconType={iconType}
            iconName={startIconName}
          />
          <span className="block">{children}</span>

          <IconRenderer
            className={iconVariants({ size })}
            iconType={iconType}
            iconName={endIconName}
          />
        </>
      )
    }
    if (!href) {
      return (
        <button
          ref={ref as React.ForwardedRef<HTMLButtonElement>}
          className={baseClassName}
          {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        >
          {renderChildren()}
        </button>
      )
    } else if (
      external ||
      href.startsWith("https://") ||
      href.startsWith("http://")
    ) {
      return (
        <a
          ref={ref as React.ForwardedRef<ButtonLinkElement>}
          href={href}
          className={baseClassName}
          {...props}
        >
          {renderChildren()}
        </a>
      )
    }

    return (
      <GatsbyLink
        ref={ref as React.ForwardedRef<ButtonLinkElement>}
        className={baseClassName}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        to={href}
        {...props}
      >
        {renderChildren()}
      </GatsbyLink>
    )
  }
)

ButtonLink.displayName = "ButtonLink"

export default ButtonLink
