import React, { Fragment } from "react"
import { FlagIcon, Icon, IconType, OtherIcon, OutlineIcon, SolidIcon } from "."
import { other, outline, solid } from "../icons"

const IconRenderer: React.FC<{
  className?: string
  iconType: IconType | undefined
  iconName?: OtherIcon | OutlineIcon | SolidIcon | FlagIcon
}> = (props) => {
  const { iconType, iconName, className } = props
  if (!iconName) return <Fragment />
  if (iconType === "other" && iconName in other) {
    return <Icon.Other name={iconName as OtherIcon} className={className} />
  }
  if (iconType === "outline" && iconName in outline) {
    return <Icon.Outline name={iconName as OutlineIcon} className={className} />
  }
  if (iconType === "solid" && iconName in solid) {
    return <Icon.Solid name={iconName as SolidIcon} className={className} />
  }
  if (iconType === "flag" && iconName in solid) {
    return <Icon.Flag name={iconName as FlagIcon} className={className} />
  }
  return <Fragment />
}

export default IconRenderer
