import React from "react"

const HTMLLogo: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      fill="none"
      viewBox="0 0 64 64"
      {...rest}
    >
      <path
        id="Vector"
        d="M4.51599 1L9.51849 57.0465L31.9665 63.247L54.4765 57.0535L59.484 1H4.51599ZM49.079 14.2695L48.7655 17.8555L48.6275 19.5H22.295L22.9235 26.5H48.0015L47.8335 28.2355L46.217 46.295L46.098 47.43L32 51.3045V51.3055L31.983 51.3145L17.8945 47.603L16.938 37H23.8455L24.335 42.4595L31.9785 44.5H32V44.227L39.6775 42.2895L40.4795 33.5H16.6305L14.939 14.4415L14.7745 12.5H49.244L49.079 14.2695Z"
        fill="#E44D26"
      />
    </svg>
  )
}

export default HTMLLogo
