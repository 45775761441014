import React from "react"

const FileText: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00008 1.33337H4.66675C3.56218 1.33337 2.66675 2.2288 2.66675 3.33337V12.6667C2.66675 13.7713 3.56218 14.6667 4.66675 14.6667H11.3334C12.438 14.6667 13.3334 13.7713 13.3334 12.6667V6.66671H10.0001C8.89551 6.66671 8.00008 5.77128 8.00008 4.66671V1.33337ZM5.33341 9.33337C5.33341 8.96518 5.63189 8.66671 6.00008 8.66671H8.00008C8.36827 8.66671 8.66675 8.96518 8.66675 9.33337C8.66675 9.70156 8.36827 10 8.00008 10H6.00008C5.63189 10 5.33341 9.70156 5.33341 9.33337ZM6.00008 11.3334C5.63189 11.3334 5.33341 11.6319 5.33341 12C5.33341 12.3682 5.63189 12.6667 6.00008 12.6667H10.3334C10.7016 12.6667 11.0001 12.3682 11.0001 12C11.0001 11.6319 10.7016 11.3334 10.3334 11.3334H6.00008Z"
        fill="currentColor"
      />
      <path
        d="M12.9429 5.33337L9.33341 1.7239V4.66671C9.33341 5.0349 9.63189 5.33337 10.0001 5.33337H12.9429Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default FileText
