import React from "react"

const ChevronLeftSmall: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2559 6.07741C11.9305 5.75197 11.4028 5.75197 11.0774 6.07741L7.74408 9.41074C7.41864 9.73618 7.41864 10.2638 7.74408 10.5893L11.0774 13.9226C11.4028 14.248 11.9305 14.248 12.2559 13.9226C12.5814 13.5971 12.5814 13.0695 12.2559 12.7441L9.51184 10L12.2559 7.25592C12.5814 6.93048 12.5814 6.40284 12.2559 6.07741Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChevronLeftSmall
