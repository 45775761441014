import React from "react"

const Bag: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66805 4.66667V3.66667C4.66805 2.74619 5.41424 2 6.33472 2H9.66805C10.5885 2 11.3347 2.74619 11.3347 3.66667V4.66667H12.6666C13.7712 4.66667 14.6666 5.5621 14.6666 6.66667L14.667 8H1.33362L1.33325 6.66667C1.33325 5.5621 2.22868 4.66667 3.33325 4.66667H4.66805ZM6.00138 3.66667C6.00138 3.48257 6.15062 3.33333 6.33472 3.33333H9.66805C9.85215 3.33333 10.0014 3.48257 10.0014 3.66667V4.66667H6.00138V3.66667Z"
        fill="currentColor"
      />
      <path
        d="M1.33472 12.0004L1.33398 9.33333H7.33374L7.33472 10.6672C7.33499 11.0353 7.63368 11.3336 8.00187 11.3333C8.37006 11.3331 8.66832 11.0344 8.66805 10.6662L8.66707 9.33333H14.6673L14.6681 12C14.6681 13.1046 13.7726 14 12.6681 14H3.33472C2.23027 14 1.33491 13.1048 1.33472 12.0004Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Bag
