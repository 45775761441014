module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.sitepoint.com","noHash":true,"noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SitePoint","short_name":"SitePoint","start_url":"/?utm_source=homescreen","background_color":"#f7f7f7","theme_color":"#3a3a3a00","display":"standalone","icons":[{"src":"/favicons/48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/384x384.png","sizes":"384x384","type":"image/png"},{"src":"/favicons/512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://editor.us-west-2b.sitepoint-staging.sitepoint.com/graphql","type":{"__all":{"limit":20},"Post":{"limit":null,"excludeFieldNames":["children","featuredImageId","enclosure","desiredSlug","lastEditedBy","link","nodeType","parent","pingStatus","terms","toPing"]},"Comment":{"limit":0},"Tag":{"limit":null,"excludeFieldNames":["contentNodes","count","description","link"]},"Category":{"limit":null,"excludeFieldNames":["contentNodes"]},"User":{"limit":null,"excludeFieldNames":["avatar","capKey","comments","extraCapabilities","nickname","registeredDate","email","capabilities","children","isContentNode","isTermNode","locale","seo","parent","nodeType","pages","url"]},"MediaItem":{"localFile":{"excludeByMimeTypes":["video/mp4","video/mov","image/jpg","image/png"],"maxFileSizeBytes":15728640,"requestConcurrency":100},"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"MenuItem":{"limit":null},"Page":{"limit":0},"Taxonomy":{"limit":0},"UserRole":{"limit":0},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":false,"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"schema":{"requestConcurrency":10,"previewRequestConcurrency":1,"perPage":50,"timeout":180000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"debug":{"preview":true,"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludePattern":"(premium|community)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#8543d8","showSpinner":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://62e4e2b2623942fe953a78abf8de2146@o388912.ingest.sentry.io/5226395","environment":"staging","enabled":true,"tracesSampleRate":0.1,"allowUrls":["sitepoint.com","webpack://"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_HS7HoEdTpP75Ip1VUJ6ie62QlbB8ciTuri8j0lbENPX","apiHost":"https://eu.posthog.com","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
