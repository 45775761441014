import React from "react"

const News1: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        d="M7.33325 3.50229L3.76711 2.70981C2.51809 2.43225 1.33325 3.38269 1.33325 4.66219V11.2638C1.33325 12.2012 1.98432 13.0128 2.89939 13.2162L7.33325 14.2015V3.50229Z"
        fill="currentColor"
      />
      <path
        d="M8.66659 14.2015L13.1004 13.2162C14.0155 13.0128 14.6666 12.2012 14.6666 11.2638V4.66219C14.6666 3.38269 13.4818 2.43225 12.2327 2.70981L8.66659 3.50229V14.2015Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default News1
