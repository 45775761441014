import { useEffect, useState } from "react"
import useIsLoggedIn from "../../hooks/use-is-logged-in"
import { GET } from "../../services/http"

const API_URL = process.env.GATSBY_BASE_URL + "/premium/api/v1"

// Functions
/**
 * Returns true if the user has an active membership
 */
export function hasMembership(user) {
  return ["active", "grace"].includes(user?.membership_status)
}

export function useCurrentUser() {
  const isLoggedIn = useIsLoggedIn()
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (isLoggedIn) {
      getAccountDetails()
        .then((data) => {
          setData(data)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [isLoggedIn])

  return { data, loading, isLoggedIn }
}

export async function getAccountDetails(options = {}) {
  return await GET(`${API_URL}/account_details/`, options).then((data) => ({
    ...data,
    is_member: hasMembership(data),
  }))
}

export async function getMembership(id, options) {
  return await GET(`${API_URL}/users/${id}/membership/`, options)
}
