import React from "react"

const CircleCheck: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 1.33337C4.31802 1.33337 1.33325 4.31814 1.33325 8.00004C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667C11.6818 14.6667 14.6666 11.6819 14.6666 8.00004C14.6666 4.31814 11.6818 1.33337 7.99992 1.33337ZM10.5159 6.75555C10.749 6.47059 10.707 6.05057 10.4221 5.81742C10.1371 5.58427 9.7171 5.62627 9.48395 5.91124L6.95043 9.00776L6.13799 8.19532C5.87764 7.93497 5.45553 7.93497 5.19518 8.19532C4.93483 8.45567 4.93483 8.87778 5.19518 9.13813L6.52851 10.4715C6.6617 10.6047 6.84501 10.6753 7.03313 10.6659C7.22125 10.6565 7.39662 10.568 7.51589 10.4222L10.5159 6.75555Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CircleCheck
