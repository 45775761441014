import React from "react"

const SunLight: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <g clipPath="url(#clip0_3590_670)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M10 .833c.46 0 .833.373.833.834V2.5a.833.833 0 1 1-1.666 0v-.833c0-.46.373-.834.833-.834M3.52 3.52a.833.833 0 0 1 1.178 0l.591.592a.833.833 0 1 1-1.178 1.178l-.592-.591a.833.833 0 0 1 0-1.179m12.96 0a.833.833 0 0 1 0 1.179l-.59.591a.833.833 0 1 1-1.18-1.178l.592-.592a.833.833 0 0 1 1.179 0M7.644 7.643a3.333 3.333 0 1 0 4.714 4.714 3.333 3.333 0 0 0-4.714-4.714M6.464 6.464a5 5 0 1 1 7.072 7.072 5 5 0 0 1-7.072-7.072M.834 10c0-.46.372-.833.833-.833H2.5a.833.833 0 1 1 0 1.666h-.833A.833.833 0 0 1 .833 10m15.833 0c0-.46.373-.833.833-.833h.833a.833.833 0 0 1 0 1.666H17.5a.833.833 0 0 1-.833-.833M5.289 14.71a.833.833 0 0 1 0 1.18l-.591.59a.833.833 0 0 1-1.179-1.178l.592-.591a.833.833 0 0 1 1.178 0m9.422 0a.833.833 0 0 1 1.178 0l.592.592a.833.833 0 0 1-1.179 1.179l-.591-.592a.833.833 0 0 1 0-1.178M10 16.668c.46 0 .833.373.833.833v.833a.833.833 0 0 1-1.666 0V17.5c0-.46.373-.833.833-.833"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3590_670">
          <path fill="#fff" d="M0 0h20v20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default SunLight
