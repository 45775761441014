import React from "react"

const Code: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2 2.89543 2.89543 2 4 2H12C13.1046 2 14 2.89543 14 4V12C14 13.1046 13.1046 14 12 14H4C2.89543 14 2 13.1046 2 12V4ZM7.13807 5.86193C7.39842 6.12228 7.39842 6.54439 7.13807 6.80474L5.94281 8L7.13807 9.19526C7.39842 9.45561 7.39842 9.87772 7.13807 10.1381C6.87772 10.3984 6.45561 10.3984 6.19526 10.1381L5 8.94281C4.4793 8.42211 4.4793 7.57789 5 7.05719L6.19526 5.86193C6.45561 5.60158 6.87772 5.60158 7.13807 5.86193ZM9.80474 5.86193C9.54439 5.60158 9.12228 5.60158 8.86193 5.86193C8.60158 6.12228 8.60158 6.54439 8.86193 6.80474L10.0572 8L8.86193 9.19526C8.60158 9.45561 8.60158 9.87772 8.86193 10.1381C9.12228 10.3984 9.54439 10.3984 9.80474 10.1381L11 8.94281C11.5207 8.42211 11.5207 7.57789 11 7.05719L9.80474 5.86193Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Code
