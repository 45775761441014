import React from "react"

const BuildingCompany: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50004 5C2.50004 3.61929 3.61933 2.5 5.00004 2.5H10C11.3808 2.5 12.5 3.61929 12.5 5V5.83333H15C16.3808 5.83333 17.5 6.95262 17.5 8.33333V15H18.3334C18.7936 15 19.1667 15.3731 19.1667 15.8333C19.1667 16.2936 18.7936 16.6667 18.3334 16.6667H1.66671C1.20647 16.6667 0.833374 16.2936 0.833374 15.8333C0.833374 15.3731 1.20647 15 1.66671 15H2.50004V5ZM12.5 15H15.8334V8.33333C15.8334 7.8731 15.4603 7.5 15 7.5H12.5V15ZM5.83337 7.5C5.83337 7.03976 6.20647 6.66667 6.66671 6.66667H8.33337C8.79361 6.66667 9.16671 7.03976 9.16671 7.5C9.16671 7.96024 8.79361 8.33333 8.33337 8.33333H6.66671C6.20647 8.33333 5.83337 7.96024 5.83337 7.5ZM5.83337 10.8333C5.83337 10.3731 6.20647 10 6.66671 10H8.33337C8.79361 10 9.16671 10.3731 9.16671 10.8333C9.16671 11.2936 8.79361 11.6667 8.33337 11.6667H6.66671C6.20647 11.6667 5.83337 11.2936 5.83337 10.8333Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default BuildingCompany
