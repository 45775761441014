import React from "react"

const ThumbsUp: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.106 2.553A1 1 0 0111 2h.616a3 3 0 012.964 3.462L14.184 8h3.88a4 4 0 013.962 4.54l-.681 5A4 4 0 0117.38 21H4a2 2 0 01-2-2v-7a2 2 0 012-2h2.382l3.724-7.447zM6 12H4v7h2v-7zm2 7h9.381a2 2 0 001.982-1.73l.682-5A2 2 0 0018.063 10h-5.047a1 1 0 01-.988-1.154l.576-3.692A1 1 0 0011.618 4L8 11.236V19z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default ThumbsUp
