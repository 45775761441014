import React from "react"
import SunLight from "../../ui/icons/outline/sun-light"
import Darkmoon from "../../ui/icons/outline/dark-moon"
import ImacComputer from "../../ui/icons/outline/imac-computer"

//FIXME: ADD DropdownMenuItem UI Component

const ThemeSelector = () => {
  return (
    <ul className="flex items-center gap-1 shrink-0">
      <li className="size-8 rounded-md flex items-center justify-center text-neutral-11 hover:bg-neutral-alpha-3 cursor-pointer">
        <SunLight className="size-5" />
      </li>
      <li className="size-8 rounded-md flex items-center justify-center text-neutral-11 hover:bg-neutral-alpha-3 cursor-pointer">
        <Darkmoon className="size-5" />
      </li>
      <li className="size-8 rounded-md flex items-center justify-center text-neutral-12 bg-neutral-alpha-3  cursor-pointer">
        <ImacComputer className="size-5" />
      </li>
    </ul>
  )
}

export default ThemeSelector
