import React from "react"

const Reddit: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
      {...rest}
    >
      <g clipPath="url(#clip0_3179_7086)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M0 24C0 10.746 10.746 0 24 0s24 10.746 24 24-10.746 24-24 24H3.476c-1.282 0-1.925-1.55-1.018-2.458L7.03 40.97A23.93 23.93 0 0 1 0 24m32.775-9.604a4 4 0 0 1-3.892-3.078v.003a4.736 4.736 0 0 0-4.065 4.682v.015c3.553.133 6.8 1.134 9.373 2.726a5.604 5.604 0 1 1 5.816 9.506c-.176 6.513-7.275 11.751-15.996 11.751s-15.81-5.233-15.995-11.74a5.604 5.604 0 1 1 5.803-9.51c2.55-1.58 5.762-2.581 9.28-2.73v-.02c0-3.324 2.525-6.067 5.76-6.414a4 4 0 0 1 7.915.81 4 4 0 0 1-3.999 4m-16.168 8.755c-1.57 0-2.92 1.56-3.013 3.592-.094 2.033 1.28 2.86 2.852 2.86 1.57 0 2.743-.74 2.836-2.772.094-2.032-1.106-3.68-2.675-3.68m17.824 3.592c-.092-2.032-1.442-3.592-3.013-3.592-1.572 0-2.77 1.648-2.676 3.68.094 2.035 1.267 2.771 2.837 2.771s2.945-.826 2.852-2.859m-4.513 5.312a.444.444 0 0 0-.368-.61 55 55 0 0 0-5.539-.27c-1.948 0-3.813.096-5.538.27a.444.444 0 0 0-.368.61 6.402 6.402 0 0 0 11.813 0"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3179_7086">
          <path fill="currentColor" d="M0 0h48v48H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Reddit
