import React from "react"
import InputField from "../../ui/input-field"
import Button from "../../ui/button"

const NewsletterSubscription = () => {
  return (
    <div className="w-full">
      <div className="text-jar-3 font-medium text-neutral-12">
        Subscribe to our newsletter
      </div>
      <p className="text-jar-3 text-neutral-11 mt-5 ">
        Get the freshest news and resources for developers, designers and
        digital creators in your inbox each week
      </p>
      <form
        className="mt-5 flex gap-3 w-full"
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <div className="w-full">
          <InputField
            size={1}
            variant={"soft"}
            className="w-full"
            placeholder="example@gmail.com"
          />
        </div>

        <Button
          className="shrink-0"
          size={2}
          variant={"solid"}
          theme={"neutral"}
        >
          Submit
        </Button>
      </form>
    </div>
  )
}

export default NewsletterSubscription
