import React from "react"

const Twitter: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (props) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M28.376 20.401 45.501.5h-4.058l-14.87 17.28L14.699.5H1l17.959 26.13L1 47.5h4.058l15.703-18.248L33.302 47.5H47zm-5.558 6.46-1.82-2.602L6.52 3.554h6.234l11.684 16.71 1.82 2.601 15.187 21.72h-6.233z"
      ></path>
    </svg>
  )
}

export default Twitter
