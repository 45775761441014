import React from "react"

const GraduateCap: React.FC<React.SVGAttributes<HTMLOrSVGElement>> = (
  props
) => {
  const { ...rest } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.627 2.588a.83.83 0 0 1 .746 0l8.333 4.167c.282.14.46.43.46.745v5a.833.833 0 0 1-1.666 0V8.848l-1.667.834v3.663a2.5 2.5 0 0 1-1.382 2.236l-3.333 1.667a2.5 2.5 0 0 1-2.236 0L5.549 15.58a2.5 2.5 0 0 1-1.382-2.236V9.682L1.294 8.245a.833.833 0 0 1 0-1.49zm-4.24 5.84-.03-.015L3.53 7.5 10 4.265 16.47 7.5l-1.826.913-.032.016L10 10.735zm.446 2.087v2.83c0 .316.179.604.461.745l3.333 1.667c.235.117.511.117.746 0l3.333-1.667a.83.83 0 0 0 .46-.745v-2.83l-3.793 1.897a.83.83 0 0 1-.746 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default GraduateCap
